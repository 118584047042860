<template>
  <div class="my_sidebar">
    <div class="info">
      <div class="avatar-section">
        <img :src="this.downloadURL+ userInfo.avatar" :onerror="defaultAvatar" alt="" class="avatar" />
        <img src="@/assets/images/my/girl.png" alt="" class="gender" v-if="userInfo.gender" />
      </div>
      <div class="info-section">
        <div class="name-row">
          <span class="name">{{userInfo.name}} </span>
          <img src="@/assets/images/home/V@2x.png" alt="" class="level" /><i>1</i>
        </div>
        <div class="other-row">
          <!-- <span class="subject">语文</span>
          <span class="jobtitle">{{userInfo.titleName}}</span>
          <span class="points">114分</span> -->
        </div>
      </div>
    </div>
    <ul class="nav">
      <li v-for="(li, index) in list" :key="index" @click="gotoPage(index, li)"
        :class="{ active: activeModule === index }" class="flex-align-between">
        <img :src="li.iconURL" alt="" />
        <span>{{ li.name }}</span>
        <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        list: [
          // 打开隐藏项目别忘了改id
          // { 
          //       id:0,
          //       iconfont:'el-icon-date',
          //       iconURL:require('../../../assets/images/my/log.png'),
          //       name:'我的日志',
          //       path: 'log',
          //   },
          {
            id: 0,
            iconfont: 'el-icon-document-copy',
            iconURL: require('@/assets/images/my/info.png'),
            name: '基本信息设置',
            path: 'infoSetting'

          }, {
            id: 1,
            iconfont: 'el-icon-finished',
            iconURL: require('@/assets/images/my/honour.png'),
            name: '我的荣誉',
            path: 'honour'

          }, {
            id: 2,
            iconfont: 'el-icon-user',
            iconURL: require('@/assets/images/my/setting.png'),
            name: '修改密码',
            path: 'modifyPsd'
          },
          // {
          //     id:3,
          //     iconfont:'el-icon-connection',
          //     iconURL:require('@/assets/images/my/connect.png'),
          //     name:'绑定授权',
          //     path: 'bindLicence'
          // },
          //     {
          //         id:4,
          //         iconfont:'el-icon-document',
          //         iconURL:require('../../../assets/images/my/suggest.png'),
          //         name:'意见建议',
          //         path: 'suggest'
          //     },{
          //         id:5,
          //         iconfont:'el-icon-document',
          //         iconURL:require('../../../assets/images/my/suggest.png'),
          //         name:'我的班级',
          //         path: 'class'
          // }
          {
            id: 6,
            iconfont: 'el-icon-user',
            iconURL: require('@/assets/images/my/log.png'),
            name: '学习成长记录',
            path: 'studylog'
          },
        ]
      };
    },
    computed: {
      ...mapState({
        role: state => state.user.role,
        userInfo: state => state.user.userInfo,
        defaultAvatar: state => state.user.defaultAvatar,
        activeModule: state => state.basics.myActiveModule,
      })
    },
    methods: {
      gotoPage(index, nav) {
        this.$store.commit('getMyActiveModule', nav.id);
        this.$router.push(nav.path)
      },
    },
    mounted() {},
    destroyed() {
      this.$store.commit('getMyActiveModule', 0);
    },
  }
</script>

<style lang="less">
  .my_sidebar {
    flex-shrink: 0;
    width: 320px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px #f5f8fa;
    border-radius: 10px;
    // max-height: 960px;

    .info {
      .avatar-section {
        position: relative;
      }

      .avatar {
        display: block;
        border: 6px solid #e7e7e7;
        border-radius: 50%;
        width: 105px;
        height: 105px;
        margin: 25px auto 18px;
      }

      .gender {
        position: absolute;
        top: 2px;
        right: 35%;
        display: block;
        width: 26px;
        height: 26px;
      }

      .name-row {
        margin-bottom: 26px;
        text-align: center;
        font-size: 18px;
      }

      .other-row {
        font-size: 16px;
        color: #666;
        text-align: center;

        span {
          margin-right: 18px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .nav {
      padding: 20px 20px;

      li {
        box-sizing: border-box;
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 0 33px 0 72px;
        // width: 100%;
        line-height: 65px;

        font-size: 16px;
        color: #595959;

        cursor: pointer;

        i {
          visibility: hidden;
        }

        &:hover {
          color: #508EF9;
          background-color: #e0ebfc;
        }

        &.active {
          color: #508EF9;
          background-color: #e0ebfc;

          i {
            visibility: visible;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .my_sidebar {
    flex-shrink: 0;
      max-width: 315px;

      .nav {
        padding: 33px 20px;

        li {
          padding: 0 13px 0 52px;
          min-width: 277px;
        }
      }
    }
  }
</style>