<template>
  <div class="my-home">
    <div class="container flex-between">
      <mySideBar></mySideBar>
      <router-view class="my-router-view"></router-view>
    </div>
  </div>
</template>

<script>
  import mySideBar from './my_sidebar.vue';
  export default {
    components: {
      mySideBar
    },
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style lang="less">
  .my-home {
    // background-color: #fafafa;
    min-width: 1100px;

    .container {
      margin: 0 auto;
      padding: 60px 0 200px;
      max-width: 1200px;
      align-items: flex-start;
    }

    .my-router-view {
      box-shadow: 0px 2px 10px 0px #f5f8fa;
      flex: 1;
      min-height: 700px;
      margin-left: 30px;
    }
  }

  @media screen and (max-width: 1250px) {
    .my-home .container {
      max-width: 990px;
    }
  }
</style>